import React, { useEffect, useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import "../invite.css"
import Constants from "../constants/api"
const Invitation = () => {
  const [code, setCode] = useState("")
  const [name, setName] = useState("")
  const [password, setPassword] = useState("")
  const [stagename, setStagename] = useState("")
  const [twitter, setTwitter] = useState("")
  const [instagram, setInstagram] = useState("")
  const [phone, setPhone] = useState("")
  const [files, setFiles] = useState(null)

  const [errorMsg, setErrorMsg] = useState("")
  const [loading, setLoading] = useState(false)
  const [needUpoad, setNeedUpload] = useState(false)
  const [verified, setVerified] = useState(false)
  const [canuse, setCanuse] = useState(true)

  useEffect(() => {
    document.body.classList.add("darken")
    const getK = new URLSearchParams(window.location.search)
    const k = getK.get("k")
    if (k) {
      setCode(k)
      handleSubmit(k)
    }
    return () => document.body.classList.remove("darken")
  }, [])

  const flashError = msg => {
    setErrorMsg(msg)
    setTimeout(() => {
      setErrorMsg("")
    }, 3500)
  }
  const handleFiles = e => {
    if (e.target.files) {
      setFiles(e.target.files)
    }
  }
  const verifyForm = () => {
    if (name === "") {
      return { status: false, msg: "Please fill out your name." }
    }

    if (stagename === "") {
      return { status: false, msg: "Please fill out your stage name." }
    }

    if (phone === "") {
      return { status: false, msg: "Please fill your phone number." }
    }

    if (needUpoad && (files === null || (files && files.length < 6))) {
      return {
        status: false,
        msg:
          "Since we can't use social images please provide us with at LEAST 6 images.",
      }
    }

    return { status: true, msg: "" }
  }
  const handleSaveInfo = () => {
    if (loading) return
    const formData = new FormData()
    const verify = verifyForm()
    if (!verify.status) {
      setErrorMsg(verify.msg)
      return
    }
    setLoading(true)
    if (canuse) {
      formData.append("canuse", "y")
    } else {
      formData.append("canuse", "n")
    }
    formData.append("name", name)
    formData.append("stagename", stagename)
    formData.append("twitter", twitter)
    formData.append("instagram", instagram)
    formData.append("code", code)
    formData.append("password", password)
    formData.append("phone", phone)
    if (files) {
      for (const file of files) {
        formData.append("files[]", file, file.name)
      }
    }
    const csrf = getCookie("XSRF-TOKEN")
    fetch(`${Constants.RIVER}save-invite-info`, {
      method: "POST",
      headers: {
        "X-XSRF-TOKEN": decodeURIComponent(csrf),
      },
      body: formData,
    })
      .then(res => res.json())
      .then(res => {
        //handle
        if ("success" in res) {
          window.location.replace("https://addfriends.com/goods/")
          return
        }
        setErrorMsg(
          "Something went wrong (error code: 38S-z), please contact hello@addfriends.com!"
        )
      })
  }
  const getCookie = name => {
    const value = `; ${document.cookie}`
    const parts = value.split(`; ${name}=`)
    if (parts.length === 2)
      return parts
        .pop()
        .split(";")
        .shift()
  }
  const handleSubmit = (thecode = "") => {
    if (loading) return
    if (code === "" && thecode === "") return
    setLoading(true)
    const toUse = thecode === "" ? code : thecode
    fetch(`${Constants.RIVER}verify-invite-code/${toUse}`, {
      credentials: "include",
    })
      .then(res => {
        if (res.status === 503) {
          return { error: "wait" }
        }
        return res.json()
      })
      .then(res => {
        //handle
        if ("error" in res) {
          //handle error
          if (res.error === "wait") {
            flashError("please wait 30 seconds and submit again.")
          } else {
            flashError(res.error)
          }
          setLoading(false)

          return
        }
        setVerified(true)
        setLoading(false)
        setPassword(res.pass)
      })
  }
  return (
    <Layout>
      <SEO title="AF Invite" />
      {verified ? (
        <div className="invite-container">
          {errorMsg !== "" ? (
            <div className="invite-error-message">{errorMsg}</div>
          ) : null}
          <div className="invite-form">
            <div className="form-item">
              <span>Legal Name</span>
              <input
                type="text"
                value={name}
                onChange={e => setName(e.target.value)}
              />
            </div>
            <div className="form-item">
              <span>Password</span>
              <input
                type="text"
                defaultValue={password}
                autoComplete="off"
                autoCapitalize="off"
                disabled
              />
            </div>

            <div className="form-item">
              <span>Stage Name</span>
              <input
                type="text"
                value={stagename}
                onChange={e => setStagename(e.target.value)}
              />
            </div>
            <div className="form-item">
              <span>Twitter</span>
              <input
                type="text"
                value={twitter}
                onChange={e => setTwitter(e.target.value)}
              />
            </div>
            <div className="form-item">
              <span>Instagram</span>
              <input
                type="text"
                value={instagram}
                onChange={e => setInstagram(e.target.value)}
              />
            </div>
            <div className="form-item">
              <span>Phone #</span>
              <input
                type="tel"
                value={phone}
                onChange={e => setPhone(e.target.value)}
              />
            </div>
            <div className="form-item right">
              <div className="full">
                can we use images / videos from your social media accounts on
                the tour?
                <div className="invite-choice">
                  <div
                    className={canuse ? "invite-btn active" : "invite-btn"}
                    onClick={() => {
                      setCanuse(true)
                      setNeedUpload(false)
                    }}
                  >
                    YES
                  </div>
                  <div
                    className={canuse ? "invite-btn" : "invite-btn active"}
                    onClick={() => {
                      setCanuse(false)
                      setNeedUpload(true)
                    }}
                  >
                    NO
                  </div>
                </div>
              </div>
              {needUpoad ? (
                <div className="full">
                  we <span className="invite-require">require 6</span>{" "}
                  instagram-safe images for your public facing AddFriends.com
                  page. Please upload them below or allow us permission to use
                  images from your social media.
                </div>
              ) : null}

              <div className={needUpoad ? "form-item-half" : "form-item-full"}>
                <button onClick={handleSaveInfo}>
                  {loading ? (
                    <div className="lds-ring">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  ) : (
                    "SAVE"
                  )}
                </button>
              </div>
              {needUpoad ? (
                <div className="form-item-half">
                  <input
                    type="file"
                    id="afupload"
                    name="files"
                    multiple
                    accept="video/*,image/*"
                    onChange={handleFiles}
                  />
                  <label htmlFor="afupload" id="uploadBtnInvite">
                    UPLOAD
                  </label>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : (
        <div className="invite-container">
          <div className="invite-code">
            <h1>Enter Invitation Code</h1>
            <div className="invite-input">
              <input
                type="text"
                value={code}
                onChange={e => setCode(e.target.value)}
                onKeyDown={e => {
                  if (e.key === "Enter") {
                    handleSubmit()
                  }
                }}
              />
            </div>
            {code.length > 0 && (
              <button className="signup-button" onClick={() => handleSubmit()}>
                {loading ? (
                  <div className="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                ) : (
                  "Sign-Up"
                )}
              </button>
            )}
          </div>
        </div>
      )}
    </Layout>
  )
}

export default Invitation
